.blog-grid-container {
    padding: 40px;
    background-color: #f5f5f5;
  }
  
  .blog-section-title {
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }


  .heading {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 10px;
  }
  
  .heading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    height: 25px;
    width: 4px;
    border-radius: 2px;
    background-color: #41e096;

  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .blog-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 20px;
  }
  
  .blog-category {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
  }
  
  .blog-title {
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .blog-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  
  .read-more-link {
    color: #00c853;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
    color: #32a372;
  }
  
  @media (max-width: 1200px) {
    .blog-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .blog-grid {
      grid-template-columns: 1fr;
    }
  }