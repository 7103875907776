section {
  color: #32325c;
}
.echo {
  background: #32325c no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  padding: 10rem 0;
  opacity: 0.9;
   /* Adjust the opacity value as needed */

  
}



footer{
    background-color: #32325c;
}
.footer{
    padding-top: 5rem;
    padding-bottom: 10rem;
    color: white;
}
.footer ul{
    list-style: none;
}
.footer ul li{
    margin-top: 0.5rem;
}
.footer ul li a{
    text-decoration: none;
    color: white;
} 
.footer ul li a{
    text-decoration: none;
    color: white;
} 
.footer ul li a:hover{
    color:#41E096 ;
} 
.footer ul li .active{
    color:#41E096 ;
} 

.responsive-image {
    width: 100%;
}
.crm-partners {
    padding: 60px 20px;
    background-color: #f8f9fa;
}

.crm-partners h3 {
    text-align: center;
    color: #32325c;
    margin-bottom: 40px;
    font-size: 2rem;
}

.partner-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}

.partner-logo {
    text-align: center;
}

.partner-logo img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.partner-logo:hover img {
    transform: scale(1.05);
}

.partner-logo p {
    color: #32325c;
    font-weight: bold;
    font-size: 1.1rem;
}

@media (max-width: 992px) {
    .partner-logo img {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 768px) {
    .partner-logos {
        gap: 30px;
    }
    
    .partner-logo img {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 576px) {
    .partner-logo img {
        width: 130px;
        height: 130px;
    }
    
    .partner-logo p {
        font-size: 1rem;
    }
}