@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.initial-hidden {
  opacity: 0;
  transform: translateY(20px);
}

.section {
  background-color: #fff; /* Light blue background */
  padding: 80px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  flex: 1;
  padding-right: 40px;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.badges {
  display: flex;
  gap: 20px;
}

.badge {
  height: 40px;
}

.imageContainer {
  flex: 1;
  position: relative;
}

.laptopImage {
  width: 100%;
  max-width: 600px;
}

/* Apply different animation delays for staggered effects */
.title.fade-in { animation-delay: 0.2s; }
.description.fade-in { animation-delay: 0.4s; }
.badges.fade-in { animation-delay: 0.6s; }
.laptopImage.fade-in { animation-delay: 0.8s; }
.mobileImage.fade-in { animation-delay: 1s; }

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 0 20px;
  }

  .content {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .title {
    font-size: 1.5rem;
  }

  .imageContainer {
    width: 100%;
  }
}
