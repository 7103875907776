/* Base styles (unchanged) */
.caseStudiesContainer {
  padding: 20px;
  padding-top: 50px;
}

.caseStudiesHeading {
  margin-bottom: 20px;
  color: #222;
  position: relative;
  padding-left: 15px;
  font-size: 1.4rem;
  padding-top: 10px;

}

.caseStudiesHeading::before {
  content: '';
  position: absolute;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
  height: 25px;
  width: 3px;
  border-radius: 2px;
  background-color: #41e096;
}

.caseStudiesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  gap: 20px;
}

.caseStudyCard {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.caseStudyCard.visible {
  animation: fadeInUp 0.6s ease forwards;
}

/* Add staggered animation delays */
.caseStudyCard:nth-child(2) { animation-delay: 0.2s; }
.caseStudyCard:nth-child(3) { animation-delay: 0.4s; }
.caseStudyCard:nth-child(4) { animation-delay: 0.6s; }

/* Add the fadeInUp animation if not already present */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.companyLogo {
  max-width: 100px;
  margin-bottom: 10px;
}

.techDetails {
  color: #555;
  font-size: 14px;
  font-family: Arial, sans-serif;

}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;

}

.title1 {
  font-size: 1rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;

}

.description {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;

}

/* Responsive grid styles */
@media (max-width: 1024px) {
  .caseStudiesGrid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .caseStudiesGrid {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }

  .caseStudiesHeading{
    font-size: 1.1rem;
    padding-left: 10px;
  }

  .caseStudyCard {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .title1 {
    font-size: 1.1rem; /* Adjust title size for smaller screens */
  }

  .description {
    font-size: 0.9rem; /* Adjust description size for smaller screens */
  }
}
