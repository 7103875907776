@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.containerfluid {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 3s ease-in-out;
  background-repeat: no-repeat;
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s forwards;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s forwards;
}

.contentWrapper {
  position: relative;
  z-index: 2;
  padding: 2rem;
}

.title {
  position: relative;
  font-size: 2.1rem;
  font-weight: bold;
  color: #41e096;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 2rem;
}

.decorativeLine {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 150px;
  height: auto;
  opacity: 0.8;
}

.subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  background-color: transparent;
  color: #41e096;
  font-weight: bold;
  border: 2px solid #41e096;
  margin-top: 2rem;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #41e096;
  color: #000;
}



@media (max-width: 991px) {
  .background {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.0) 100%
    );
  }

  .title {
    font-size: 2.2rem;
    text-align: left;
    color: #41e096;

  }

  .subtitle {
    font-size: 1.2rem;
    text-align: left;
  }

  .button {
    display: ruby-base;
    margin: 20px auto 0;
  }
}


@media (min-width: 992px) {
  .splitContainer {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("../Dataset/bac7.jpg");

  }

  .contentSection {
    width: 40%;
    background-image: url("../Dataset/bac7.jpg");
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  .contentWrapper {
    padding: 4rem;
  }

  .imageSection {
    width: 60%;
    position: relative;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
  }

  .title {
    color: #333;
    text-shadow: none;
    font-size: 2.2rem;
  }

  .subtitle {
    color: #666;
    text-shadow: none;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 90%;
  }

  .button {
    background-color: #41e096;
    color: white;
    border: none;
    padding: 1rem 2rem;
  }

  .button:hover {
    background-color: #33b378;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(65, 224, 150, 0.2);
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.7) 30%,
        rgba(0, 0, 0, 0.4) 60%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  #particles-js {
    position: relative;
    z-index: 2;
  }

  .decorativeLine {
    opacity: 1;
    width: 180px;
  }
}

/* Small screen styles */
@media (max-width: 500px) {
  .containerfluid {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .splitContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .contentSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentWrapper {
    position: relative;
    z-index: 2;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.0) 100%
    );
    z-index: 1;
  }

  .title {
    color: #41e096;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    font-size: 2.2rem;
    text-align: left;
  }

  .subtitle {
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
    text-align: left;
  }

  .button {
    background-color: transparent;
    border: 2px solid #41e096;
    color: #41e096;
    margin-top: 1.5rem;
    align-self: flex-start;
    display: inline-block;
    margin-left: 0;
    padding: 0.5rem 1.2rem;
    font-size: 0.9rem;
  }

  .button:hover {
    background-color: #41e096;
    color: #000;
  }

  .decorativeLine {
    width: 120px;
    opacity: 0.9;
  }
}