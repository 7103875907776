@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom font sizes */
h1 {
  font-size: 2rem; /* Adjust the font size as needed */
}

h2 {
  font-size: 1.5rem; /* Adjust the font size as needed */
}

h3 {
  font-size: 1.25rem; /* Adjust the font size as needed */
}

p {
  font-size: 1.2rem; /* Adjust the font size as needed */
}

/* Add more custom styles as needed */
@keyframes Echo_fadeIn__LWkA0 {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Echo_fade-in__3apq_ {
  animation: Echo_fadeIn__LWkA0 1s forwards;
}

.Echo_initial-hidden__2rHvs {
  opacity: 0;
  transform: translateY(20px);
}

.Echo_section__1NmEU {
  background-color: #fff; /* Light blue background */
  padding: 80px 0;
}

.Echo_container__coyj3 {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Echo_content__QNMOv {
  flex: 1 1;
  padding-right: 40px;
}

.Echo_title__hAjQ0 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.Echo_description__bY_Sn {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.Echo_badges__Z5wt9 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.Echo_badge__1Na6c {
  height: 40px;
}

.Echo_imageContainer__1okEG {
  flex: 1 1;
  position: relative;
}

.Echo_laptopImage__vUWUx {
  width: 100%;
  max-width: 600px;
}

/* Apply different animation delays for staggered effects */
.Echo_title__hAjQ0.Echo_fade-in__3apq_ { animation-delay: 0.2s; }
.Echo_description__bY_Sn.Echo_fade-in__3apq_ { animation-delay: 0.4s; }
.Echo_badges__Z5wt9.Echo_fade-in__3apq_ { animation-delay: 0.6s; }
.Echo_laptopImage__vUWUx.Echo_fade-in__3apq_ { animation-delay: 0.8s; }
.Echo_mobileImage__1az0e.Echo_fade-in__3apq_ { animation-delay: 1s; }

@media (max-width: 768px) {
  .Echo_container__coyj3 {
    flex-direction: column;
    padding: 0 20px;
  }

  .Echo_content__QNMOv {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .Echo_title__hAjQ0 {
    font-size: 1.5rem;
  }

  .Echo_imageContainer__1okEG {
    width: 100%;
  }
}

.Button_button__2j_YQ{
    border-radius: 2rem;
    background-color: #41E096;
    border: none;
    font-weight: bolder;
}

@keyframes Introduction_fadeIn__N_r0_ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Introduction_fadeOut__2be_h {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Introduction_containerfluid__1IqZ_ {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.Introduction_background__duoLi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 3s ease-in-out;
  background-repeat: no-repeat;
}

.Introduction_fadeIn__N_r0_ {
  opacity: 1;
  animation: Introduction_fadeIn__N_r0_ 1s forwards;
}

.Introduction_fadeOut__2be_h {
  opacity: 0;
  animation: Introduction_fadeOut__2be_h 1s forwards;
}

.Introduction_contentWrapper__wiRqI {
  position: relative;
  z-index: 2;
  padding: 2rem;
}

.Introduction_title__1JpKK {
  position: relative;
  font-size: 2.1rem;
  font-weight: bold;
  color: #41e096;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 2rem;
}

.Introduction_decorativeLine__2I7lH {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 150px;
  height: auto;
  opacity: 0.8;
}

.Introduction_subtitle__1Np_I {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

.Introduction_button__Kg1dN {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  background-color: transparent;
  color: #41e096;
  font-weight: bold;
  border: 2px solid #41e096;
  margin-top: 2rem;
  transition: all 0.3s ease;
}

.Introduction_button__Kg1dN:hover {
  background-color: #41e096;
  color: #000;
}



@media (max-width: 991px) {
  .Introduction_background__duoLi {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.0) 100%
    );
  }

  .Introduction_title__1JpKK {
    font-size: 2.2rem;
    text-align: left;
    color: #41e096;

  }

  .Introduction_subtitle__1Np_I {
    font-size: 1.2rem;
    text-align: left;
  }

  .Introduction_button__Kg1dN {
    display: ruby-base;
    margin: 20px auto 0;
  }
}


@media (min-width: 992px) {
  .Introduction_splitContainer__3BRWs {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/bac7.69243607.jpg);

  }

  .Introduction_contentSection__2hYTo {
    width: 40%;
    background-image: url(/static/media/bac7.69243607.jpg);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  .Introduction_contentWrapper__wiRqI {
    padding: 4rem;
  }

  .Introduction_imageSection__Rv-3y {
    width: 60%;
    position: relative;
    -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
  }

  .Introduction_title__1JpKK {
    color: #333;
    text-shadow: none;
    font-size: 2.2rem;
  }

  .Introduction_subtitle__1Np_I {
    color: #666;
    text-shadow: none;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 90%;
  }

  .Introduction_button__Kg1dN {
    background-color: #41e096;
    color: white;
    border: none;
    padding: 1rem 2rem;
  }

  .Introduction_button__Kg1dN:hover {
    background-color: #33b378;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(65, 224, 150, 0.2);
  }

  .Introduction_background__duoLi {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.7) 30%,
        rgba(0, 0, 0, 0.4) 60%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  #Introduction_particles-js__2g66U {
    position: relative;
    z-index: 2;
  }

  .Introduction_decorativeLine__2I7lH {
    opacity: 1;
    width: 180px;
  }
}

/* Small screen styles */
@media (max-width: 500px) {
  .Introduction_containerfluid__1IqZ_ {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .Introduction_splitContainer__3BRWs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Introduction_contentSection__2hYTo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Introduction_contentWrapper__wiRqI {
    position: relative;
    z-index: 2;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .Introduction_background__duoLi::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.0) 100%
    );
    z-index: 1;
  }

  .Introduction_title__1JpKK {
    color: #41e096;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    font-size: 2.2rem;
    text-align: left;
  }

  .Introduction_subtitle__1Np_I {
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
    text-align: left;
  }

  .Introduction_button__Kg1dN {
    background-color: transparent;
    border: 2px solid #41e096;
    color: #41e096;
    margin-top: 1.5rem;
    align-self: flex-start;
    display: inline-block;
    margin-left: 0;
    padding: 0.5rem 1.2rem;
    font-size: 0.9rem;
  }

  .Introduction_button__Kg1dN:hover {
    background-color: #41e096;
    color: #000;
  }

  .Introduction_decorativeLine__2I7lH {
    width: 120px;
    opacity: 0.9;
  }
}
.projects-container {
  padding: 40px 0;
  background-color: #fff;
  overflow: hidden;
}

.projects-heading {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #333;
}

.projects-subheading {
  font-size: 28px;
  margin-top: 30px;
  color: #333;
}

.slider-container {
  margin: 0 auto;
  max-width: 100%; 
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff; /* Limit the width of the slider */
}

.project-item {
  padding: 0 20px; 
  padding-top: 20px;
  padding-bottom: 20px; /* Increased padding between logos */
}

.project-image {
  max-width: 300px;  /* Increased from 100px to 200px */
  max-height: 200px;  /* Increased from 50px to 100px */
  width: auto;
  height: auto;
  object-fit: contain;
  padding: 40px;
}

.project-image .hover


/* Override some slick-carousel styles */
.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.strip_contactStrip__2nPFf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 8px 5%;
  font-family: 'Arial', sans-serif;
}

.strip_contactInfo__3xO8E {
  display: flex;
  align-items: center;
}

.strip_phoneIcon__2eIAC {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #fff;
}

.strip_phoneNumber__3rjpx {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.strip_socialIcons__3oyaU {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.strip_iconLink__L56ja {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333333;
  transition: all 0.3s ease;
}

.strip_icon__p_F9t {
  width: 14px;
  height: 14px;
  color: #ffffff;
  transition: color 0.3s ease;
}

/* Twitter */
.strip_iconLink__L56ja:hover .strip_twitterIcon__3OJHE {
  color: #1DA1F2;
}

/* Facebook */
.strip_iconLink__L56ja:hover .strip_facebookIcon__1mja0 {
  color: #1877F2;
}

/* LinkedIn */
.strip_iconLink__L56ja:hover .strip_linkedinIcon__2HqpL {
  color: #0077B5;
}

/* Ensure background color remains unchanged on hover */
.strip_iconLink__L56ja:hover {
  background-color: #333333;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .strip_contactStrip__2nPFf {
    padding: 10px 2%;
  }

  .strip_phoneIcon__2eIAC {
    width: 16px;
    height: 16px;
  }

  .strip_phoneNumber__3rjpx {
    font-size: 14px;
  }

  .strip_socialIcons__3oyaU {
    grid-gap: 10px;
    gap: 10px;
  }

  .strip_iconLink__L56ja {
    width: 24px;
    height: 24px;
  }

  .strip_icon__p_F9t {
    width: 12px;
    height: 12px;
  }
}
.service6_softwareModules__3QmAm {
    padding: 20px;
    background-color: #fff; /* Light blue background */

  }
  
  .service6_heading__3kjyQ {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.3rem;
    padding-top: 10px;
    font-weight: bold;

  }
  
  .service6_heading__3kjyQ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    transform: translateY(-50%);
    height: 25px;
    width: 3px;
    border-radius: 2px;
    background-color: #41e096;

  }
  
  .service6_modulesContainer__2X9pg {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
  }
  
  .service6_moduleCard__7oIxb {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.3s ease;
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(20px);
  }
  
  .service6_moduleCard__7oIxb.service6_visible__1nHkL {
    animation: service6_fadeInUp__3XL5G 0.6s ease forwards;
  }
  
  /* Stagger the animations */
  .service6_moduleCard__7oIxb:nth-child(2) { animation-delay: 0.3s; }
  .service6_moduleCard__7oIxb:nth-child(3) { animation-delay: 0.5s; }
  .service6_moduleCard__7oIxb:nth-child(4) { animation-delay: 0.7s; }
  .service6_moduleCard__7oIxb:nth-child(5) { animation-delay: 0.9s; }
  .service6_moduleCard__7oIxb:nth-child(6) { animation-delay: 1.1s; }
  
  .service6_cardContent__2WOzN {
    flex: 1 1;
  }
  
  .service6_cardContent__2WOzN h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    padding-bottom: 20px;
    font-family: Arial, sans-serif;

  }
  
  .service6_cardContent__2WOzN p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.4;
    padding-bottom: 20px;
    font-family: Arial, sans-serif;

  }
  
  .service6_readMore__3WyWE {
    text-decoration: none;
    color: #000;;
    font-weight: bold;
    font-size: 14px;
    font-family: Arial, sans-serif;

  }

  .service6_readMore__3WyWE:hover {
    text-decoration: underline;
    color: #32a372
  }
  
  .service6_cardIcon__3kdmP {
    margin-left: 20px;
  }
  
  .service6_cardIcon__3kdmP svg {
    width: 50px;
    height: 50px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .service6_moduleCard__7oIxb {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% - 20px); /* Stack cards vertically on small screens */
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .service6_heading__3kjyQ {
      font-size: 1.1rem;
      padding-left: 10px;
    }
  
    .service6_moduleCard__7oIxb {
      padding: 15px;
    }
  
    .service6_cardContent__2WOzN h3 {
      font-size: 16px;
    }
  
    .service6_cardContent__2WOzN p {
      font-size: 13px;
      margin-bottom: 15px;
    }
  
    .service6_cardIcon__3kdmP svg {
      width: 40px;
      height: 40px;
    }
  
    .service6_readMore__3WyWE {
      font-size: 13px;
    }
  }
  
  /* Add these animation keyframes */
  @keyframes service6_fadeInUp__3XL5G {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
/* Base styles (unchanged) */
.case_caseStudiesContainer__92frN {
  padding: 20px;
  padding-top: 50px;
}

.case_caseStudiesHeading__21hNE {
  margin-bottom: 20px;
  color: #222;
  position: relative;
  padding-left: 15px;
  font-size: 1.4rem;
  padding-top: 10px;

}

.case_caseStudiesHeading__21hNE::before {
  content: '';
  position: absolute;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
  height: 25px;
  width: 3px;
  border-radius: 2px;
  background-color: #41e096;
}

.case_caseStudiesGrid__1drZa {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  grid-gap: 20px;
  gap: 20px;
}

.case_caseStudyCard__2KDq- {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.case_caseStudyCard__2KDq-.case_visible__z1JQf {
  animation: case_fadeInUp__1mg8T 0.6s ease forwards;
}

/* Add staggered animation delays */
.case_caseStudyCard__2KDq-:nth-child(2) { animation-delay: 0.2s; }
.case_caseStudyCard__2KDq-:nth-child(3) { animation-delay: 0.4s; }
.case_caseStudyCard__2KDq-:nth-child(4) { animation-delay: 0.6s; }

/* Add the fadeInUp animation if not already present */
@keyframes case_fadeInUp__1mg8T {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.case_companyLogo__3kbtV {
  max-width: 100px;
  margin-bottom: 10px;
}

.case_techDetails__2GuXQ {
  color: #555;
  font-size: 14px;
  font-family: Arial, sans-serif;

}

.case_title__2PM5x {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;

}

.case_title1__Ir2CJ {
  font-size: 1rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;

}

.case_description__1OmH8 {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;

}

/* Responsive grid styles */
@media (max-width: 1024px) {
  .case_caseStudiesGrid__1drZa {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .case_caseStudiesGrid__1drZa {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }

  .case_caseStudiesHeading__21hNE{
    font-size: 1.1rem;
    padding-left: 10px;
  }

  .case_caseStudyCard__2KDq- {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .case_title1__Ir2CJ {
    font-size: 1.1rem; /* Adjust title size for smaller screens */
  }

  .case_description__1OmH8 {
    font-size: 0.9rem; /* Adjust description size for smaller screens */
  }
}

/* Base styles (unchanged) */

.members_industryPartnership__1Fva9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
}

.members_imageRow__2uWZI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.members_personImage__rB6q8 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 0 15px;
  border-radius: 20%;
  transform: scale(1.1) rotate(-5deg);
  transition: transform 0.3s ease;
}

.members_personImage__rB6q8.members_round__2OSws {
  border-radius: 50%;
  transform: scale(1.1) rotate(5deg);
}

.members_colorBlock__16b68 {
  width: 120px;
  height: 120px;
  margin: 0 15px;
  border-radius: 25%;
  transform: scale(1.1) rotate(5deg);
  transition: transform 0.3s ease;
}

.members_yellow__10_8T { background-color: #000; }
.members_green__A7eUk { background-color: #d82809f1; }
.members_blue__1G7kR { background-color: #0c7c2e; }

.members_circle__adacs {
  border-radius: 50%;
  transform: scale(1.1) rotate(-5deg);
}

.members_roundedSquare__3hUQ_ {
  border-radius: 20%;
  transform: scale(1.1) rotate(5deg);
}

.members_rectangle__3nod4 {
  width: 160px;
  height: 80px;
  border-radius: 10%;
  transform: scale(1.1) rotate(-5deg);
}

.members_textContent__2PNQ0 {
  text-align: center;
  margin: 20px 0;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.members_nathanLogo__32I0K {
  max-width: 200px;
  margin: 10px 0;
}

p {
  font-size: 18px;
  color: #555;
}

/* Responsive scaling for smaller screens */
@media (max-width: 1024px) {
  .members_personImage__rB6q8,
  .members_colorBlock__16b68 {
    width: 100px;
    height: 100px;
    margin: 0 12px;
  }

  .members_rectangle__3nod4 {
    width: 140px;
    height: 70px;
  }

  h2 {
    font-size: 22px;
  }

  .members_nathanLogo__32I0K {
    max-width: 180px;
  }

  p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .members_personImage__rB6q8,
  .members_colorBlock__16b68 {
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }

  .members_rectangle__3nod4 {
    width: 120px;
    height: 60px;
  }

  h2 {
    font-size: 20px;
  }

  .members_nathanLogo__32I0K {
    max-width: 160px;
  }

  p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .members_personImage__rB6q8,
  .members_colorBlock__16b68 {
    width: 60px;
    height: 60px;
    margin: 0 8px;
  }

  .members_rectangle__3nod4 {
    width: 100px;
    height: 50px;
  }

  h2 {
    font-size: 18px;
  }

  .members_nathanLogo__32I0K {
    max-width: 140px;
  }

  p {
    font-size: 12px;
  }
}

.solutions_caseStudiesContainer__1IUL5 {
    font-family: Arial, sans-serif;
    padding: 20px;
    padding-top: 50px;
  }
  
  .solutions_caseStudiesHeading__1SVNd {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 10px;
  }
  
  .solutions_caseStudiesHeading__1SVNd::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    height: 25px;
    width: 3px;
    border-radius: 2px;
    background-color: #41e096;
  }
  



.solutions_container__2BnaB {
    font-family: Arial, sans-serif;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .solutions_mainCard__3Lvmw {
    background-color: #e4ebfe; /* Light blue background */
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }
  
  .solutions_cardContent__14-6H {
    flex: 1 1;
    max-width: 50%;
  }
  
  .solutions_title__22wVm {
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .solutions_description__2RM7n {
    font-size: 16px;
    color: #555;
    margin-bottom: 25px;
    line-height: 1.5;
  }
  
  .solutions_button__3VPvd {
    background-color: white;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .solutions_button__3VPvd:hover {
    background-color: #f0f0f0;
  }

  .solutions_buttonLink__7os_c{
    color:#333;
  }

    .solutions_buttonLink__7os_c:hover{
        color:#41e096;
    }
  
  .solutions_imageContainer__1aDr4 {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .solutions_image__3XCkd {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .solutions_forSMEs__27uKy {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #4b0082;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .solutions_smallCardsContainer__3bdhj {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .solutions_smallCard__2Ybkt {
    flex: 1 1;
    border-radius: 8px;
    padding: 25px;
    color: white;
  }
  
  .solutions_blueCard__2hgDQ {
    background-color: #41E096;
  }
  
  .solutions_greenCard__1CMh0 {
    background-color: #000;
    
  }
  
  .solutions_smallCardTitle__3aN22 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .solutions_smallCardDescription__1zcC9 {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  .solutions_smallCard__2Ybkt .solutions_button__3VPvd {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }
  
  .solutions_smallCard__2Ybkt .solutions_button__3VPvd:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {

    .solutions_caseStudiesHeading__1SVNd{
        font-size: 1.1rem;
        padding-left: 10px;
    }

    .solutions_title__22wVm{
        font-size: 22px;
    }

    .solutions_description__2RM7n{
        font-size: 14px;
    }

    .solutions_mainCard__3Lvmw {
      flex-direction: column;
    }
  
    .solutions_cardContent__14-6H {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .solutions_smallCardsContainer__3bdhj {
      flex-direction: column;
    }

    .solutions_button__3VPvd {
        padding: 8px 16px; /* Reduce padding for smaller screens */
        font-size: 14px; /* Reduce font size for smaller screens */
      }

    .solutions_smallCard__2Ybkt .solutions_button__3VPvd {
        padding: 8px 16px; /* Reduce padding for smaller screens */
        font-size: 14px; /* Reduce font size for smaller screens */
      }
  }
.blogs1_caseStudiesContainer__2ocy0 {
    padding: 20px;
    padding-top: 50px;
  }
  
  .blogs1_caseStudiesHeading__1KO2j {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.4rem;
    padding-top: 10px;
    font-weight: bold;

  }
  
  .blogs1_caseStudiesHeading__1KO2j::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    height: 25px;
    width: 3px;
    border-radius: 2px;
    background-color: #41e096;
  }
  




.blogs1_container__1Fj2k {
    font-family: Arial, sans-serif;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .blogs1_contentWrapper__3g55G {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    margin-bottom: 25px;
  }
  
  .blogs1_mainNews__14-RB {
    flex: 1.5 1;
    min-width: 250px;
    background-color: #f0f4ff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .blogs1_mainImage__5AA59 {
    width: 100%;
    height: auto;
    display: block;
    max-height: 250px;
    object-fit: cover;
  }
  
  .blogs1_mainContent__njVmc {
    padding: 20px;
  }
  
  .blogs1_tag__3YMNa {
    display: inline-block;
    padding: 4px 8px;
    background-color: #41E096;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .blogs1_mainTitle__2VQh7 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .blogs1_readMore__8U2Q2 {
    color: #444;
    text-decoration: none;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-weight: bold;
  }
  
  .blogs1_sideNews__2LMCk {
    flex: 1 1;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .blogs1_newsItem__fQoy1 {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    background-color: #f0f4ff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .blogs1_sideImage__3Sp8t {
    width: 120px;
    height: 100px;
    object-fit: cover;
  }
  
  .blogs1_sideContent__1R5iR {
    padding: 15px;
    flex: 1 1;
  }
  
  .blogs1_sideTitle__2B0x2 {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blogs1_buttonWrapper__3QEN0 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .blogs1_viewAllButton__Vy_5N {
    background-color: transparent;
    border: 1px solid #41E096;
    color: #41E096;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .blogs1_viewAllButton__Vy_5N:hover {
    background-color: #41E096;
    color: white;
  }

.blogs1_readMore__8U2Q2{
  background-color: #f0f4ff;
  border-color: #f0f4ff;

}


  .blogs1_readMore__8U2Q2:hover {
    color: #41E096
  }
  
  @media (max-width: 768px) {
    .blogs1_contentWrapper__3g55G {
      flex-direction: column;
    }
  
    .blogs1_mainNews__14-RB, .blogs1_sideNews__2LMCk {
      width: 100%;
    }

    .blogs1_caseStudiesHeading__1KO2j {
      font-size: 1.1rem;
    }



  }

/* Modal Styles */
.blogs1_modalOverlay__29SYN {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.blogs1_modalContent__2cEB6 {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.blogs1_closeButton__3KD1A {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.blogs1_closeButton__3KD1A:hover {
  color: #1a1a1a;
}

.blogs1_modalGrid__16EzB {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.blogs1_modalImageSection__2_rtW {
  width: 100%;
}

.blogs1_modalImage__39hpi {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.blogs1_modalDetails__2H8ki {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.blogs1_modalTag__1vj0B {
  display: inline-block;
  padding: 4px 8px;
  background-color: #41E096;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  align-self: flex-start;
}

.blogs1_modalDetails__2H8ki h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.blogs1_modalDescription__F8Fdd {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .blogs1_modalOverlay__29SYN {
    padding: 1rem;
  }

  .blogs1_modalContent__2cEB6 {
    padding: 1.5rem;
  }

  .blogs1_modalDetails__2H8ki h2 {
    font-size: 1.5rem;
  }

  .blogs1_modalDescription__F8Fdd {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .blogs1_modalOverlay__29SYN {
    padding: 0;
  }

  .blogs1_modalContent__2cEB6 {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .blogs1_closeButton__3KD1A {
    top: 0.5rem;
    right: 0.5rem;
  }

  .blogs1_modalDetails__2H8ki h2 {
    font-size: 1.25rem;
  }

  .blogs1_modalDescription__F8Fdd {
    font-size: 0.9rem;
  }
}

.blogs1_modalDescription__F8Fdd p {
  margin-bottom: 1rem;
}

.blogs1_modalDescription__F8Fdd p:last-child {
  margin-bottom: 0;
}



.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
 }

 .navbar .dropdown-menu {
    background-color: #000; /* Black background for dropdown */
  }
  
  .navbar .dropdown-item {
    color: white; /* White text for dropdown items */
  }
  
  .navbar .dropdown-item:hover {
    background-color: #333; /* Optional: Change background on hover */
  }
  
  .navbar .dropdown-divider {
    border-color: #444; /* Optional: Style the dropdown divider */
  }
  
  .navbar .navitem .navlink {
    font-size: 10px;
  }
.Footer_footer__320pW {
    background-color: #000;
    color: white;
    padding: 20px 0;
  }
  
  .Footer_column__3E50i {
    margin-bottom: 20px;
  }
  
  .Footer_heading__iR4xl {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .Footer_list__2fMu8 {
    list-style-type: none;
    padding: 0;
    font-size: 0.9rem;
  }
  
  .Footer_logo__3m9bW {
    width: 50%;
    height: auto;
    margin-left: 40px;
  }
  
  .Footer_navLink__39G-Y {
    color: white;
    text-decoration: none;
  }
  
  .Footer_navLink__39G-Y:hover {
    color: #40e096;
  }
  
  .Footer_bottomFooter__wKmPm {
    color: #40e096;
    padding: 10px 0;
  }
  
  .Footer_copyright__3otGf {
    font-size: 0.8rem;
    margin: 0;
  }
  
  .Footer_socialIcon__21zQH {
    font-size: 1.5rem;
    margin-left: 10px;
  }
.support_supportSection__1GAa_ {
    background-color: #f0f4ff; /* Light blue background */
    padding: 40px 0;
  }
  
  .support_container__2fSqE {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #e4ebfe; /* Slightly darker blue for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .support_supportTextWrapper__35-b5 {
    border-radius: 5px;
    display: flex;
    align-items: center;
    top: -30px;
    color:#222;
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
    padding-top: 10px;
    font-size: 1.3rem;
    color: #333;
    font-weight: bold;
  }

  .support_supportTextWrapper__35-b5 h3{
    font-size: 2rem;
    color: #333;
    font-weight: bold;

  }
  
  .support_supportTextWrapper__35-b5::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 25px;
    margin-right: 10px;
    border-radius: 2px;
    color: #000;
    background-color: #41e096;

  }
  
  .support_supportText__gWlTz {
    font-size: 1rem;
    font-weight: bold;
    color: #000; /* Dark blue */
    margin: 0;
    padding-bottom: 20px;
  }
  
  .support_contentWrapper__1RRud {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .support_imageWrapper__3QJrI {
    flex: 1 1;
    padding-right: 20px;
  }
  
  .support_supportImage__eIN0n {
    width: 80%;
    border-radius: 10px;
  }
  
  .support_textContent__3A4_b {
    flex: 1 1;
    padding-left: 20px;
  }
  
  .support_title__oKp7C {
    font-size: 1.4rem;
    color: #002855; /* Dark blue */
    margin-bottom: 15px;
  }
  
  .support_description__4UKol {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .support_contactButton__2u0RP {
    background-color: #41E096; /* Bright blue */
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .support_contactButton__2u0RP:hover {
    background-color: #32a372; /* Darker blue on hover */
  }
  
  @media (max-width: 768px) {
    .support_contentWrapper__1RRud {
      flex-direction: column;
      text-align: center;
    }

    .support_supportTextWrapper__35-b5 h3{
      font-size: 1.1rem;
    }
  
    .support_imageWrapper__3QJrI, .support_textContent__3A4_b {
      padding: 0;
    }
  
    .support_textContent__3A4_b {
      margin-top: 20px;
    }
  
    .support_supportImage__eIN0n {
      width: 80%; /* Adjust image size for smaller screens */
      margin: 0 auto;
    }

    .support_title__oKp7C{
      font-size: 1.1rem;
    }

    .support_description__4UKol {
      font-size: 1rem;
    }
  }
  
section {
  color: #32325c;
}
.Styles_echo__3b-mn {
  background: #32325c no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  padding: 10rem 0;
  opacity: 0.9;
   /* Adjust the opacity value as needed */

  
}



footer{
    background-color: #32325c;
}
.Styles_footer__7P-f-{
    padding-top: 5rem;
    padding-bottom: 10rem;
    color: white;
}
.Styles_footer__7P-f- ul{
    list-style: none;
}
.Styles_footer__7P-f- ul li{
    margin-top: 0.5rem;
}
.Styles_footer__7P-f- ul li a{
    text-decoration: none;
    color: white;
} 
.Styles_footer__7P-f- ul li a{
    text-decoration: none;
    color: white;
} 
.Styles_footer__7P-f- ul li a:hover{
    color:#41E096 ;
} 
.Styles_footer__7P-f- ul li .Styles_active__3S3zJ{
    color:#41E096 ;
} 

.Styles_responsive-image__48W8z {
    width: 100%;
}
.Styles_crm-partners__XKf4G {
    padding: 60px 20px;
    background-color: #f8f9fa;
}

.Styles_crm-partners__XKf4G h3 {
    text-align: center;
    color: #32325c;
    margin-bottom: 40px;
    font-size: 2rem;
}

.Styles_partner-logos__2Oq2z {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
}

.Styles_partner-logo__1FsiD {
    text-align: center;
}

.Styles_partner-logo__1FsiD img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.Styles_partner-logo__1FsiD:hover img {
    transform: scale(1.05);
}

.Styles_partner-logo__1FsiD p {
    color: #32325c;
    font-weight: bold;
    font-size: 1.1rem;
}

@media (max-width: 992px) {
    .Styles_partner-logo__1FsiD img {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 768px) {
    .Styles_partner-logos__2Oq2z {
        grid-gap: 30px;
        gap: 30px;
    }
    
    .Styles_partner-logo__1FsiD img {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 576px) {
    .Styles_partner-logo__1FsiD img {
        width: 130px;
        height: 130px;
    }
    
    .Styles_partner-logo__1FsiD p {
        font-size: 1rem;
    }
}
/* Styles.module.css */

.Home_floating-icon__2srTK {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensures icons are above other content */
    background-color: #000; /* Background color for visibility, adjust as needed */
    border-radius: 50%;
    padding: 10px; /* Space around the icon */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
}

.Home_floating-icon__2srTK a {
    color: #fff; /* Color of the icon */
    text-decoration: none; /* Remove underline from link */
    font-size: 24px; /* Size of the icon */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px; /* Width of the icon container */
    height: 50px; /* Height of the icon container */
}

.Home_floating-icon__2srTK.Home_whatsapp-icon__TYK_h {
    background-color: #25D366; /* WhatsApp green color */
}

.Home_floating-icon__2srTK.Home_phone-icon__3ezem {
    background-color: #34b7f1; /* Phone icon background color */
    margin-top: 60px; /* Add margin to avoid overlap with WhatsApp icon */
}

.store_storeContainer__2LgBQ {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Slider Section Styles */
.store_sliderSection__3zvkj {
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  padding: 2rem 0;
  border-radius: 1rem;
}

.store_caseStudiesHeading__2fGCY {
  margin-bottom: 20px;
  color: #333;
  position: relative;
  padding-left: 15px;
  font-size: 1.4rem;
  padding-top: 10px;
  font-weight: bold;

}

.store_caseStudiesHeading__2fGCY::before {
  content: '';
  position: absolute;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
  height: 25px;
  width: 3px;
  border-radius: 2px;
  background-color: #41e096;
}

.store_sliderWrapper__1fhH6 {
  position: relative;
  padding: 0 3rem;
}

.store_productsContainer__1gQlY {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  transition: transform 0.3s ease-in-out;

}

/* Product Card Styles */
.store_productCard__2G34q {
  background: #ffffff;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.store_productCard__2G34q:hover {
  transform: translateY(-5px);
}

.store_imageWrapper__2BDdO {
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.store_productImage__1KRCT {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.store_productCard__2G34q:hover .store_productImage__1KRCT {
  transform: scale(1.05);
}

.store_productInfo__2bmNR {
  padding: 1.5rem;
}

.store_productInfo__2bmNR h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.store_productInfo__2bmNR p {
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.store_productActions__zkhvY {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.store_price__rFsuw {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
}

.store_detailsButton__2FbJr {
  padding: 0.5rem 1rem;
  background-color: #40E096;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.store_detailsButton__2FbJr:hover {
  background-color: #2ecc71;
}

/* Navigation Button Styles */
.store_navButton__iSsUs {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.store_navButton__iSsUs:hover {
  background: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.store_prevButton__1pHe_ {
  left: 0;
}

.store_nextButton__3zDQK {
  right: 0;
}

/* Modal Styles */
.store_modalOverlay__XBHou {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.store_modalContent__ZLSca {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.store_closeButton__3t4Ra {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.store_closeButton__3t4Ra:hover {
  color: #1a1a1a;
}

.store_modalGrid__3bhDa {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.store_modalImageSection__2DEpC {
  width: 100%;
}

.store_modalImage__4klwd {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.store_modalDetails__1cmk_ {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.store_modalDetails__1cmk_ h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.store_modalDescription__12BjM {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.store_modalPrice__3MPJb {
  font-size: 1.5rem;
  font-weight: 600;
  color: #40E096;
}

.store_specifications__2lejI {
  margin-top: 1.5rem;
}

.store_specifications__2lejI h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.store_specRow__2Taz9 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
}

.store_specLabel__2p8FR {
  font-weight: 500;
  color: #666;
}

.store_specValue__2H85m {
  color: #1a1a1a;
}

.store_specList__2_rZW {
  list-style: disc;
  margin-left: 1.25rem;
  color: #1a1a1a;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .store_productsContainer__1gQlY {
    grid-template-columns: repeat(2, 1fr);
  }

  .store_modalGrid__3bhDa {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .store_productsContainer__1gQlY {
    grid-template-columns: 1fr;
  }

  .store_modalGrid__3bhDa {
    grid-template-columns: 1fr;
  }

  .store_modalContent__ZLSca {
    padding: 1rem;
  }

  .store_specRow__2Taz9 {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .store_sliderWrapper__1fhH6 {
    padding: 0 1rem;
  }

  .store_navButton__iSsUs {
    width: 2rem;
    height: 2rem;
  }

  .store_modalOverlay__XBHou {
    padding: 1rem;
  }

  .store_modalContent__ZLSca {
    padding: 1.5rem;
  }

  .store_modalGrid__3bhDa {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .store_modalDetails__1cmk_ h2 {
    font-size: 1.5rem;
  }

  .store_modalDescription__12BjM {
    font-size: 1rem;
  }

  .store_modalPrice__3MPJb {
    font-size: 1.25rem;
  }

  .store_specifications__2lejI h3 {
    font-size: 1.1rem;
  }

  .store_specRow__2Taz9 {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}

@media (max-width: 640px) {
  .store_storeContainer__2LgBQ {
    padding: 1rem;
  }

  .store_caseStudiesHeading__2fGCY {
    font-size: 1.2rem;
  }

  .store_navButton__iSsUs {
    display: none;
  }

  .store_productsContainer__1gQlY {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .store_productsContainer__1gQlY::-webkit-scrollbar {
    display: none;
  }

  .store_productCard__2G34q {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }

  .store_modalContent__ZLSca {
    padding: 1rem;
    max-height: 100vh;
    border-radius: 0;
  }

  .store_modalImage__4klwd {
    max-height: 200px;
    object-fit: contain;
  }

  .store_modalOverlay__XBHou {
    padding: 0;
  }

  .store_modalContent__ZLSca {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .store_closeButton__3t4Ra {
    top: 0.5rem;
    right: 0.5rem;
  }

  .store_modalImage__4klwd {
    max-height: 200px;
    object-fit: contain;
  }

  .store_modalDetails__1cmk_ h2 {
    font-size: 1.25rem;
  }

  .store_modalDescription__12BjM {
    font-size: 0.9rem;
  }

  .store_modalPrice__3MPJb {
    font-size: 1.1rem;
  }

  .store_specifications__2lejI h3 {
    font-size: 1rem;
  }

  .store_specLabel__2p8FR, .store_specValue__2H85m {
    font-size: 0.9rem;
  }

  .store_sliderWrapper__1fhH6 {
    padding: 0;
    overflow: hidden;
  }

  .store_mobileProductsContainer__1HNTC {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .store_mobileProductsContainer__1HNTC::-webkit-scrollbar {
    display: none;
  }

  .store_mobileProductsContainer__1HNTC .store_productCard__2G34q {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }

  .store_navButton__iSsUs {
    width: 2rem;
    height: 2rem;
    top: calc(50% - 1rem);
  }

  .store_prevButton__1pHe_ {
    left: 0.5rem;
  }

  .store_nextButton__3zDQK {
    right: 0.5rem;
  }
}

.Services1_servicesContainer__38v-v {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  color: #fff;
}

.Services1_servicesOverlay__3l8rp {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 40px;
  border-radius: 15px;
  max-width: 1400px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.Services1_servicesHeading__3d9SJ {
  font-size: 2rem;
  margin-bottom: 40px;
  text-align: center;
  color: #41e096;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.Services1_serviceGrid__3dCzP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.Services1_serviceCard__3th4A {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.Services1_serviceCard__3th4A:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.Services1_serviceImageWrapper__b-CS6 {
  height: 200px;
  overflow: hidden;
}

.Services1_serviceImage__1FXZV {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.Services1_serviceCard__3th4A:hover .Services1_serviceImage__1FXZV {
  transform: scale(1.1);
}

.Services1_serviceContent__3v-PZ {
  padding: 20px;
}

.Services1_serviceTitle__BJfKs {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #41e096;
}

.Services1_serviceDescription__4HLQ1 {
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .Services1_servicesOverlay__3l8rp {
    padding: 30px 20px;
  }

  .Services1_servicesHeading__3d9SJ {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .Services1_serviceGrid__3dCzP {
    grid-template-columns: 1fr;
  }

  .Services1_serviceCard__3th4A {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .Services1_servicesHeading__3d9SJ {
    font-size: 1.7rem;
  }

  .Services1_serviceTitle__BJfKs {
    font-size: 1.3rem;
  }

  .Services1_serviceDescription__4HLQ1 {
    font-size: 0.9rem;
  }
}
.login_container__Au1_e {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/nairobi2.bba35c7d.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .login_wrapper__B9Pj5 {
    width: 400px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  }
  
  .login_title__DX-Sf {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #41E096;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .login_form__3dSV4 {
    display: flex;
    flex-direction: column;
  }
  
  .login_input__37MJ8 {
    margin-bottom: 20px;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .login_input__37MJ8::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .login_input__37MJ8:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .login_button__1yzeG {
    padding: 12px;
    background-color: #000;
    color: #41E096;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease;
  }
  
  .login_button__1yzeG:hover {
    background-color: #41E096;
    color: #000
    
  }
  
  .login_forgotPassword__2XdQG {
    margin-top: 20px;
    text-align: center;
  }
  
  .login_link__eoAIw {
    color: #41E096;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .login_link__eoAIw:hover {
    color: #fff;
  }
.process1_processSection__2pjTW {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.process1_sectionTitle__5okle {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

/* Targeting the paragraph immediately after .sectionTitle */
.process1_sectionTitle__5okle + p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.process1_sectionTitle__5okle + p + p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}


.process1_timelineContainer__3rPoB {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.process1_timelineContainer__3rPoB::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 50px;
  margin-left: -3px;
}

.process1_timelineItem__2Hw95 {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
}

.process1_timelineIcon__3t924 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 20px;
  background-color: #000;
  top: 0;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.process1_timelineContent__2Yupw {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-left: 60px;
}

.process1_timelineContent__2Yupw h3 {
  color: #000;
  margin-bottom: 15px;
  font-size: 1.5rem; /* Targeting the header */
}

.process1_timelineContent__2Yupw p {
  margin-bottom: 0;
  line-height: 1.6;
  font-size: 1.1rem; /* Targeting the paragraph */
}

@media (max-width: 768px) {
  .process1_sectionTitle__5okle {
    font-size: 1.5rem;
  }

  .process1_sectionTitle__5okle + p {
    font-size: 1rem; /* Adjusting paragraph size after section title for smaller screens */
  }

  .process1_sectionTitle__5okle + p + p {
    font-size: 1rem; /* Adjusting paragraph size after section title for smaller screens */
  }


  .process1_timelineContent__2Yupw h3 {
    font-size: 1.2rem; /* Adjusting the header size for smaller screens */
  }

  .process1_timelineContent__2Yupw p {
    font-size: 0.9rem; /* Adjusting the paragraph size for smaller screens */
  }
}

/* Insights.module.css */
.Insights_insightsSection__3c0FY {
    padding: 60px 20px;
  }
  
  .Insights_sectionHeading__3A3IE {
    position: relative;
    margin-bottom: 40px;
    padding-left: 20px;
  }
  
  .Insights_sectionHeading__3A3IE h2 {
    font-size: 1.4rem;
    color: #333;
  }
  
  .Insights_sectionHeading__3A3IE::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #41e096;
  }
  
  .Insights_insightsContainer__1L5nt {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
  }
  
  .Insights_insightCard__3sGdV {
    flex: 1 1;
    min-width: 300px;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .Insights_insightImage__s3jx- {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .Insights_insightContent__3DDw9 {
    padding: 20px;
  }
  
  .Insights_category__25YvM {
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .Insights_title__3UDZm {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .Insights_readMore__1rWpC {
    color: #41e096;
    text-decoration: none;
    font-weight: bold;
    background-color: #fff;
    border-color: #fff;
  }
  
  .Insights_readMore__1rWpC:hover {
    text-decoration: underline;
    color: #32a372
  }
  
  @media (max-width: 768px) {
    .Insights_insightCard__3sGdV {
      flex: 0 0 100%;
    }

    .Insights_sectionHeading__3A3IE h2 {
      font-size: 1.1rem;
    }
  }

/* Modal Styles */
.Insights_modalOverlay__1N3SL {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.Insights_modalContent__jv3ho {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.Insights_closeButton__25FHh {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.Insights_closeButton__25FHh:hover {
  color: #1a1a1a;
}

.Insights_modalGrid__1ActT {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.Insights_modalImageSection__2yJKL {
  width: 100%;
}

.Insights_modalImage__1jszi {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.Insights_modalDetails__hHtnH {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.Insights_modalTag__2sKuF {
  display: inline-block;
  padding: 4px 8px;
  background-color: #41E096;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  align-self: flex-start;
}

.Insights_modalDetails__hHtnH h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.Insights_modalDescription__nhfuI {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.Insights_modalDescription__nhfuI p {
  margin-bottom: 1rem;
}

.Insights_modalDescription__nhfuI p:last-child {
  margin-bottom: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Insights_modalOverlay__1N3SL {
    padding: 1rem;
  }

  .Insights_modalContent__jv3ho {
    padding: 1.5rem;
  }

  .Insights_modalDetails__hHtnH h2 {
    font-size: 1.5rem;
  }

  .Insights_modalDescription__nhfuI {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .Insights_modalOverlay__1N3SL {
    padding: 0;
  }

  .Insights_modalContent__jv3ho {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .Insights_closeButton__25FHh {
    top: 0.5rem;
    right: 0.5rem;
  }

  .Insights_modalDetails__hHtnH h2 {
    font-size: 1.25rem;
  }

  .Insights_modalDescription__nhfuI {
    font-size: 0.9rem;
  }
}

.data_supportSection__3kKvk {
    padding: 40px 0;
  }
  
  .data_container__2izXg {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
  }
  
  .data_supportTextWrapper__3FplQ {
    border-radius: 5px;
    display: flex;
    align-items: center;
    top: -30px;
    color:#000;
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
    padding-top: 10px;
    font-size: 1.3rem;
  }
  
  .data_supportTextWrapper__3FplQ::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 25px;
    margin-right: 10px;
    border-radius: 2px;
    color: #000;
    background-color: #41e096;

  }
  
  .data_supportText__28wto {
    font-size: 0.7rem;
    font-weight: bold;
    color: #000; /* Dark blue */
    margin: 0;
    padding-bottom: 20px;
  }
  
  .data_contentWrapper__1Zt_u {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .data_imageWrapper__3JkHT {
    flex: 1 1;
    padding-right: 20px;
  }
  
  .data_supportImage__3KRlr {
    width: 80%;
    border-radius: 10px;
    padding-left: 80px;
  }
  
  .data_textContent__1qy3q {
    flex: 1 1;
    padding-left: 20px;
  }
  
  .data_title__N-Iq0 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #000; /* Dark blue */
    margin-bottom: 15px;
  }
  
  .data_description__3ChyS {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
    padding-right: 30px;
  }
  
  .data_contactButton__1L36k {
    background-color: #41E096; /* Bright blue */
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .data_contactButton__1L36k:hover {
    background-color: #32a372; /* Darker blue on hover */
  }
  
  @media (max-width: 768px) {
    .data_contentWrapper__1Zt_u {
      flex-direction: column;
      text-align: center;
    }
  
    .data_imageWrapper__3JkHT, .data_textContent__1qy3q {
      padding: 0;
    }
  
    .data_textContent__1qy3q {
      margin-top: 20px;
    }
  
    .data_supportImage__3KRlr {
      width: 80%; /* Adjust image size for smaller screens */
      margin: 0 auto;
      padding-left: 0px;
    }

    .data_title__N-Iq0{
      font-size: 1.3rem;
    }

    .data_description__3ChyS {
      font-size: 0.9rem;
      text-align: left;
      padding-left: 50px;
    }
  }
  
/* General Styles */
section {
    padding: 2rem;
  }
  
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  /* Process1 Component */
  .website_process-step__2djUx {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .website_process-step__2djUx img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  
  /* Process Component */
  .website_process-container__1fz2Z {
    background-color: #f5f9fc;
    padding: 3rem 1rem;
  }
  
  .website_process-item__6OYyo {
    margin-bottom: 2rem;
  }
  
  .website_process-item__6OYyo img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  
  /* FAQ Component */
  .website_faq-container__3Vtlf {
    padding: 3rem 1rem;
  }
  
  .website_faq-question__TXklf {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .website_faq-answer__2sz2H {
    margin-left: 1rem;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.875rem;
    }
  
    .website_process-step__2djUx img,
    .website_process-item__6OYyo img {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .website_faq-question__TXklf {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    h1 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.3rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    .website_process-step__2djUx,
    .website_process-item__6OYyo {
      padding: 0.75rem;
    }
  
    .website_faq-answer__2sz2H {
      margin-left: 0.5rem;
    }
  }
  
/* Pricing.css */

.pricing-container {
  margin-top: 20px;
  padding-left: 20px; /* Add left padding to create space */
  padding-right: 20px;
}

.pricing-heading {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #32325c; /* Set text color to white */
}

.pricing-options {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem; /* Add gap between the sections */
  justify-content: center;
  margin-left: -20px; /* Compensate for the padding on the container */
  margin-right: -20px; /* Center pricing options horizontally */
}

.pricing-option {
  max-width: 300px; /* Limit the width of each pricing option */
  width: 100%; /* Ensure pricing options take full width */
  padding: 0.5rem;
  background-color: #edfdf5; /* Set background color to black */
  color: #32325c; /* Set text color to white */
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  flex: 1 1; /* Each section takes equal space */
  padding: 0.5rem;
}

.pricing-card {
  padding: 1rem;
  flex: 1 1; /* Each section takes equal space */
  padding: 0.5rem;
}

.pricing-title {
  font-size: 1.5rem; /* Smaller font size for the title */
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #32325c; /* Set text color to white */
}

.pricing-badge {
  background-color: #ffa500;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem; /* Smaller font size for the badge */
}

.pricing-price {
  font-size: 2rem; /* Larger font size for the price */
  margin-bottom: 0.5rem;
  color: #41e096; /* Set text color to white */
}

.pricing-features {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pricing-feature {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feature-icon {
  margin-right: 0.5rem;
}

.feature-text {
  font-size: 1rem; /* Smaller font size for the feature text */
  color: #32325c; /* Set text color to white */
}

.subscribe-button {
  display: inline-block;
  background-color: #40E096;
  border-color: #40E096;
  color: #000;
  padding: 0.5rem 1rem; /* Smaller padding for the button */
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  font-size: 1rem; /* Smaller font size for the button */
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #32a372;
}

.crm_app__2bJ6k {
    font-family: 'Arial', sans-serif;
}

.crm_main__PjPJ0 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.crm_hero__IdgC6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.crm_heroContent__2i_eH {
    flex: 1 1;
}

.crm_heroContent__2i_eH h1 {
    font-size: 2.2rem;
    color: #32325c;
    margin-bottom: 1rem;
}

.crm_heroContent__2i_eH p {
    font-size: 1.2rem;
    color: #555;
}

.crm_heroImage__38Bdk {
    width: 40%;
}

.crm_features__3pUFP {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    margin-bottom: 3rem;
}

.crm_features__3pUFP h3 {
    font-size: 1.2rem;
}

.crm_featureCard__2QK50 {
    text-align: center;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.crm_featureCard__2QK50:hover {
    transform: translateY(-5px);
}

.crm_featureIcon__3vcfd {
    font-size: 2.2rem;
    color: #41E096;
    margin-bottom: 1rem;
}


.crm_description__hOoQH p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 3rem;
}

.crm_benefits__2_F07 {
    background-color: #fffc;
    color: #555;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 3rem;
}

.crm_benefits__2_F07 h2 {
    margin-bottom: 1rem;
}

.crm_benefits__2_F07 ul {
    list-style-type: none;
    padding-left: 0;
}

.crm_benefits__2_F07 li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
    font-size: 1.1rem;
}

.crm_benefits__2_F07 li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #000;
}

.crm_partners__23EdW {
    margin-bottom: 3rem;
}

.crm_partners__23EdW h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.crm_partnerLogos__2ERDF {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.crm_partnerLogo__4xBly {
    text-align: center;
    margin-bottom: 1rem;
}

.crm_partnerLogo__4xBly img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 0.5rem;
}

.crm_callToAction__2aMhr {
    background-color: #f8f9fa;
    padding: 3rem;
    text-align: center;
    border-radius: 10px;
}

.crm_callToAction__2aMhr h2 {
    color: #32325c;
    margin-bottom: 1rem;
}

.crm_callToAction__2aMhr p {
    margin-bottom: 2rem;
}

.crm_ctaButton__2nCIX {
    background-color: #41E096;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.crm_ctaButton__2nCIX:hover {
    background-color: #32a372;
    color:#fff
}


/* Responsive styles */
    @media (max-width: 768px) {
        .crm_hero__IdgC6 {
            flex-direction: column;
        }

        .crm_heroImage__38Bdk {
            width: 100%;
            margin-top: 2rem;
        }

        .crm_features__3pUFP {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }

        .crm_features__3pUFP h3 {
            font-size: 1rem;
        }

        .crm_benefits__2_F07 {
            padding: 1rem;
        }

        .crm_benefits__2_F07 h2 {
            font-size: 1.3rem;
        }

        .crm_benefits__2_F07 ul {
            padding-left: 1rem;
        }

        .crm_benefits__2_F07 li {
            font-size: 1rem;
        }

        .crm_partnerLogo__4xBly img {
            width: 100px;
            height: 100px;
        }

        .crm_callToAction__2aMhr {
            padding: 2rem;
        }

        .crm_callToAction__2aMhr h2 {
            font-size: 1.3rem;
        }

        .crm_callToAction__2aMhr p {
            font-size: 1rem;
        }

        .crm_ctaButton__2nCIX {
            padding: 0.5rem 1rem;
            font-size: 1rem;
        }

        .crm_description__hOoQH p {
            font-size: 1rem;
        }

        .crm_heroContent__2i_eH h1 {
            font-size: 1.6rem;
        }

        .crm_heroContent__2i_eH p {
            font-size: 1.1rem;
        }
    }

.social_app__1fAjy {
    font-family: 'Arial', sans-serif;
}

.social_main__DdCIb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.social_serviceSection__220PN {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.social_serviceSection__220PN h2 {
    color: #333;
    margin-bottom: 1rem;
}

.social_serviceSection__220PN p {
    color: #666;
    margin-bottom: 1.5rem;
}

.social_iconGrid__3bIHr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.social_iconItem__2pAbE {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.social_icon__n18CP {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.social_ctaSection__2BBO- {
    background-color: #f8f9fa;
    text-align: center;
    padding: 3rem;
    border-radius: 10px;
    margin-top: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
}

.social_ctaSection__2BBO- h2 {
    margin-bottom: 1rem;
}

.social_ctaSection__2BBO- p {
    margin-bottom: 2rem;
}

.social_ctaButton__2OxPv {
    background-color: #41E096;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.social_ctaButton__2OxPv:hover {
    background-color: #f8f9fa;
}

@media (max-width: 768px) {
    .social_main__DdCIb {
        padding: 1rem;
    }
    
    .social_serviceSection__220PN {
        padding: 1.5rem;
    }
    
    .social_iconGrid__3bIHr {
        grid-template-columns: repeat(2, 1fr);
    }
}
.Blog_blog-grid-container__3tv0G {
    padding: 40px;
    background-color: #f5f5f5;
  }
  
  .Blog_blog-section-title__1HHpX {
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }


  .Blog_heading__28QkN {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 10px;
  }
  
  .Blog_heading__28QkN::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    height: 25px;
    width: 4px;
    border-radius: 2px;
    background-color: #41e096;

  }
  
  .Blog_blog-grid__iS5f_ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .Blog_blog-card__3JPrX {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .Blog_blog-image__HcMmb {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .Blog_blog-content__2eisx {
    padding: 20px;
  }
  
  .Blog_blog-category__2qZXe {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
  }
  
  .Blog_blog-title__Z8DP8 {
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .Blog_blog-description__3IUwJ {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  
  .Blog_read-more-link__3TWbZ {
    color: #00c853;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
  }
  
  .Blog_read-more-link__3TWbZ:hover {
    text-decoration: underline;
    color: #32a372;
  }
  
  @media (max-width: 1200px) {
    .Blog_blog-grid__iS5f_ {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .Blog_blog-grid__iS5f_ {
      grid-template-columns: 1fr;
    }
  }
/* ContactSection.module.css */
.contact1_contactSection__nJoY_ {
    padding: 40px;
    background-color: #f8f9fa;
  }
  
  .contact1_contentWrapper__17yKO {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .contact1_scheduleSection__3O1rR,
  .contact1_formSection__39z-Y {
    flex: 1 1;
    padding: 20px;
  }

  .contact1_formSection__39z-Y h2{
    font-size: 1.4rem;
  }

  .contact1_formSection__39z-Y p{
    font-size: 1rem;
  }
  
  .contact1_scheduleSection__3O1rR {
    margin-right: 40px;
  }

  .contact1_scheduleSection__3O1rR h2{
    font-size: 1.4rem;
  }

  .contact1_scheduleSection__3O1rR p{
    font-size: 1rem;
  }
  
  .contact1_meetingImage__2paS1 {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .contact1_location__22NB9 {
    margin-top: 20px;
  }
  
  .contact1_formSection__39z-Y h2 {
    margin-bottom: 20px;
  }
  
  .contact1_formGroup__idTLB {
    margin-bottom: 20px;
  }
  
  .contact1_formGroup__idTLB label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact1_formGroup__idTLB input,
  .contact1_formGroup__idTLB textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact1_phoneInput__HwgGQ {
    display: flex;
  }
  
  .contact1_phoneInput__HwgGQ select {
    width: 100px;
    margin-right: 10px;
  }
  
  .contact1_submitButton__2cPoj {
    background-color: #41e096;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contact1_submitButton__2cPoj:hover {
    background-color: #33b378;
  }
  
  .contact1_mapContainer__3CZgY {
    margin-top: 2rem;
    text-align: center;
  }
  
  .contact1_mapHeading__1nO-m {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .contact1_mapIframe__hdJBD {
    width: 100%;
    max-width: 800px;
    height: 450px;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .contact1_contentWrapper__17yKO {
      flex-direction: column;
    }
  
    .contact1_scheduleSection__3O1rR {
      margin-right: 0;
      margin-bottom: 40px;
    }
  
    .contact1_mapIframe__hdJBD {
      height: 300px;
    }
  }
.BlogDetail_blog-detail-container__2t_ca {
    max-width: 800px;
    margin: 40px auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .BlogDetail_blog-image__16xmv {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .BlogDetail_blog-content__EbCff {
    padding: 40px;
  }
  
  .BlogDetail_blog-category__34p-u {
    font-size: 14px;
    font-weight: bold;
    color: #00c853;
    margin-bottom: 16px;
    display: block;
    text-transform: uppercase;
  }
  
  .BlogDetail_blog-title__1UkdS {
    font-size: 32px;
    color: #333;
    margin-bottom: 24px;
    line-height: 1.2;
  }
  
  .BlogDetail_blog-meta__2Ov4- {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 14px;
    color: #666;
  }
  
  .BlogDetail_blog-description__3Du5G {
    font-size: 18px;
    color: #444;
    line-height: 1.8;
    margin-bottom: 32px;
  }
  
  .BlogDetail_back-link__9zuW5 {
    display: inline-block;
    color: #00c853;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }
  
  .BlogDetail_back-link__9zuW5:hover {
    text-decoration: underline;
    color: #32a372;
  }
