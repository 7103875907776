@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom font sizes */
h1 {
  font-size: 2rem; /* Adjust the font size as needed */
}

h2 {
  font-size: 1.5rem; /* Adjust the font size as needed */
}

h3 {
  font-size: 1.25rem; /* Adjust the font size as needed */
}

p {
  font-size: 1.2rem; /* Adjust the font size as needed */
}

/* Add more custom styles as needed */