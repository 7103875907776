/* ContactSection.module.css */
.contactSection {
    padding: 40px;
    background-color: #f8f9fa;
  }
  
  .contentWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .scheduleSection,
  .formSection {
    flex: 1;
    padding: 20px;
  }

  .formSection h2{
    font-size: 1.4rem;
  }

  .formSection p{
    font-size: 1rem;
  }
  
  .scheduleSection {
    margin-right: 40px;
  }

  .scheduleSection h2{
    font-size: 1.4rem;
  }

  .scheduleSection p{
    font-size: 1rem;
  }
  
  .meetingImage {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .location {
    margin-top: 20px;
  }
  
  .formSection h2 {
    margin-bottom: 20px;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .phoneInput {
    display: flex;
  }
  
  .phoneInput select {
    width: 100px;
    margin-right: 10px;
  }
  
  .submitButton {
    background-color: #41e096;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: #33b378;
  }
  
  .mapContainer {
    margin-top: 2rem;
    text-align: center;
  }
  
  .mapHeading {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .mapIframe {
    width: 100%;
    max-width: 800px;
    height: 450px;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
    }
  
    .scheduleSection {
      margin-right: 0;
      margin-bottom: 40px;
    }
  
    .mapIframe {
      height: 300px;
    }
  }