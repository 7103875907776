.processSection {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.sectionTitle {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

/* Targeting the paragraph immediately after .sectionTitle */
.sectionTitle + p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.sectionTitle + p + p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}


.timelineContainer {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timelineContainer::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 50px;
  margin-left: -3px;
}

.timelineItem {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
}

.timelineIcon {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 20px;
  background-color: #000;
  top: 0;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.timelineContent {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-left: 60px;
}

.timelineContent h3 {
  color: #000;
  margin-bottom: 15px;
  font-size: 1.5rem; /* Targeting the header */
}

.timelineContent p {
  margin-bottom: 0;
  line-height: 1.6;
  font-size: 1.1rem; /* Targeting the paragraph */
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 1.5rem;
  }

  .sectionTitle + p {
    font-size: 1rem; /* Adjusting paragraph size after section title for smaller screens */
  }

  .sectionTitle + p + p {
    font-size: 1rem; /* Adjusting paragraph size after section title for smaller screens */
  }


  .timelineContent h3 {
    font-size: 1.2rem; /* Adjusting the header size for smaller screens */
  }

  .timelineContent p {
    font-size: 0.9rem; /* Adjusting the paragraph size for smaller screens */
  }
}
