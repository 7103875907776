.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../Dataset/nairobi2.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .wrapper {
    width: 400px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #41E096;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .input {
    margin-bottom: 20px;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .button {
    padding: 12px;
    background-color: #000;
    color: #41E096;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #41E096;
    color: #000
    
  }
  
  .forgotPassword {
    margin-top: 20px;
    text-align: center;
  }
  
  .link {
    color: #41E096;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #fff;
  }