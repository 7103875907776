.caseStudiesContainer {
    padding: 20px;
    padding-top: 50px;
  }
  
  .caseStudiesHeading {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.4rem;
    padding-top: 10px;
    font-weight: bold;

  }
  
  .caseStudiesHeading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    height: 25px;
    width: 3px;
    border-radius: 2px;
    background-color: #41e096;
  }
  




.container {
    font-family: Arial, sans-serif;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .contentWrapper {
    display: flex;
    gap: 25px;
    margin-bottom: 25px;
  }
  
  .mainNews {
    flex: 1.5;
    min-width: 250px;
    background-color: #f0f4ff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .mainImage {
    width: 100%;
    height: auto;
    display: block;
    max-height: 250px;
    object-fit: cover;
  }
  
  .mainContent {
    padding: 20px;
  }
  
  .tag {
    display: inline-block;
    padding: 4px 8px;
    background-color: #41E096;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .mainTitle {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .readMore {
    color: #444;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
  }
  
  .sideNews {
    flex: 1;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .newsItem {
    display: flex;
    gap: 15px;
    background-color: #f0f4ff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .sideImage {
    width: 120px;
    height: 100px;
    object-fit: cover;
  }
  
  .sideContent {
    padding: 15px;
    flex: 1;
  }
  
  .sideTitle {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .buttonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .viewAllButton {
    background-color: transparent;
    border: 1px solid #41E096;
    color: #41E096;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .viewAllButton:hover {
    background-color: #41E096;
    color: white;
  }

.readMore{
  background-color: #f0f4ff;
  border-color: #f0f4ff;

}


  .readMore:hover {
    color: #41E096
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
    }
  
    .mainNews, .sideNews {
      width: 100%;
    }

    .caseStudiesHeading {
      font-size: 1.1rem;
    }



  }

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #1a1a1a;
}

.modalGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.modalImageSection {
  width: 100%;
}

.modalImage {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.modalDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modalTag {
  display: inline-block;
  padding: 4px 8px;
  background-color: #41E096;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  align-self: flex-start;
}

.modalDetails h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.modalDescription {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modalOverlay {
    padding: 1rem;
  }

  .modalContent {
    padding: 1.5rem;
  }

  .modalDetails h2 {
    font-size: 1.5rem;
  }

  .modalDescription {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .modalOverlay {
    padding: 0;
  }

  .modalContent {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .closeButton {
    top: 0.5rem;
    right: 0.5rem;
  }

  .modalDetails h2 {
    font-size: 1.25rem;
  }

  .modalDescription {
    font-size: 0.9rem;
  }
}

.modalDescription p {
  margin-bottom: 1rem;
}

.modalDescription p:last-child {
  margin-bottom: 0;
}
