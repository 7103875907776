.softwareModules {
    padding: 20px;
    background-color: #fff; /* Light blue background */

  }
  
  .heading {
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 15px;
    font-size: 1.3rem;
    padding-top: 10px;
    font-weight: bold;

  }
  
  .heading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    transform: translateY(-50%);
    height: 25px;
    width: 3px;
    border-radius: 2px;
    background-color: #41e096;

  }
  
  .modulesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .moduleCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 0 1 calc(50% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.3s ease;
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(20px);
  }
  
  .moduleCard.visible {
    animation: fadeInUp 0.6s ease forwards;
  }
  
  /* Stagger the animations */
  .moduleCard:nth-child(2) { animation-delay: 0.3s; }
  .moduleCard:nth-child(3) { animation-delay: 0.5s; }
  .moduleCard:nth-child(4) { animation-delay: 0.7s; }
  .moduleCard:nth-child(5) { animation-delay: 0.9s; }
  .moduleCard:nth-child(6) { animation-delay: 1.1s; }
  
  .cardContent {
    flex: 1;
  }
  
  .cardContent h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    padding-bottom: 20px;
    font-family: Arial, sans-serif;

  }
  
  .cardContent p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.4;
    padding-bottom: 20px;
    font-family: Arial, sans-serif;

  }
  
  .readMore {
    text-decoration: none;
    color: #000;;
    font-weight: bold;
    font-size: 14px;
    font-family: Arial, sans-serif;

  }

  .readMore:hover {
    text-decoration: underline;
    color: #32a372
  }
  
  .cardIcon {
    margin-left: 20px;
  }
  
  .cardIcon svg {
    width: 50px;
    height: 50px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .moduleCard {
      flex: 0 1 calc(100% - 20px); /* Stack cards vertically on small screens */
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 1.1rem;
      padding-left: 10px;
    }
  
    .moduleCard {
      padding: 15px;
    }
  
    .cardContent h3 {
      font-size: 16px;
    }
  
    .cardContent p {
      font-size: 13px;
      margin-bottom: 15px;
    }
  
    .cardIcon svg {
      width: 40px;
      height: 40px;
    }
  
    .readMore {
      font-size: 13px;
    }
  }
  
  /* Add these animation keyframes */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  