.storeContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Slider Section Styles */
.sliderSection {
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  padding: 2rem 0;
  border-radius: 1rem;
}

.caseStudiesHeading {
  margin-bottom: 20px;
  color: #333;
  position: relative;
  padding-left: 15px;
  font-size: 1.4rem;
  padding-top: 10px;
  font-weight: bold;

}

.caseStudiesHeading::before {
  content: '';
  position: absolute;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
  height: 25px;
  width: 3px;
  border-radius: 2px;
  background-color: #41e096;
}

.sliderWrapper {
  position: relative;
  padding: 0 3rem;
}

.productsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  transition: transform 0.3s ease-in-out;

}

/* Product Card Styles */
.productCard {
  background: #ffffff;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.productCard:hover {
  transform: translateY(-5px);
}

.imageWrapper {
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.productCard:hover .productImage {
  transform: scale(1.05);
}

.productInfo {
  padding: 1.5rem;
}

.productInfo h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.productInfo p {
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.productActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
}

.detailsButton {
  padding: 0.5rem 1rem;
  background-color: #40E096;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.detailsButton:hover {
  background-color: #2ecc71;
}

/* Navigation Button Styles */
.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.navButton:hover {
  background: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #1a1a1a;
}

.modalGrid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;
}

.modalImageSection {
  width: 100%;
}

.modalImage {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.modalDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modalDetails h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.modalDescription {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.modalPrice {
  font-size: 1.5rem;
  font-weight: 600;
  color: #40E096;
}

.specifications {
  margin-top: 1.5rem;
}

.specifications h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.specRow {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
}

.specLabel {
  font-weight: 500;
  color: #666;
}

.specValue {
  color: #1a1a1a;
}

.specList {
  list-style: disc;
  margin-left: 1.25rem;
  color: #1a1a1a;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .productsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .modalGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .productsContainer {
    grid-template-columns: 1fr;
  }

  .modalGrid {
    grid-template-columns: 1fr;
  }

  .modalContent {
    padding: 1rem;
  }

  .specRow {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .sliderWrapper {
    padding: 0 1rem;
  }

  .navButton {
    width: 2rem;
    height: 2rem;
  }

  .modalOverlay {
    padding: 1rem;
  }

  .modalContent {
    padding: 1.5rem;
  }

  .modalGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .modalDetails h2 {
    font-size: 1.5rem;
  }

  .modalDescription {
    font-size: 1rem;
  }

  .modalPrice {
    font-size: 1.25rem;
  }

  .specifications h3 {
    font-size: 1.1rem;
  }

  .specRow {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

@media (max-width: 640px) {
  .storeContainer {
    padding: 1rem;
  }

  .caseStudiesHeading {
    font-size: 1.2rem;
  }

  .navButton {
    display: none;
  }

  .productsContainer {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .productsContainer::-webkit-scrollbar {
    display: none;
  }

  .productCard {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }

  .modalContent {
    padding: 1rem;
    max-height: 100vh;
    border-radius: 0;
  }

  .modalImage {
    max-height: 200px;
    object-fit: contain;
  }

  .modalOverlay {
    padding: 0;
  }

  .modalContent {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .closeButton {
    top: 0.5rem;
    right: 0.5rem;
  }

  .modalImage {
    max-height: 200px;
    object-fit: contain;
  }

  .modalDetails h2 {
    font-size: 1.25rem;
  }

  .modalDescription {
    font-size: 0.9rem;
  }

  .modalPrice {
    font-size: 1.1rem;
  }

  .specifications h3 {
    font-size: 1rem;
  }

  .specLabel, .specValue {
    font-size: 0.9rem;
  }

  .sliderWrapper {
    padding: 0;
    overflow: hidden;
  }

  .mobileProductsContainer {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .mobileProductsContainer::-webkit-scrollbar {
    display: none;
  }

  .mobileProductsContainer .productCard {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }

  .navButton {
    width: 2rem;
    height: 2rem;
    top: calc(50% - 1rem);
  }

  .prevButton {
    left: 0.5rem;
  }

  .nextButton {
    right: 0.5rem;
  }
}
