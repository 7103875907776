/* Base styles (unchanged) */

.industryPartnership {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
}

.imageRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.personImage {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 0 15px;
  border-radius: 20%;
  transform: scale(1.1) rotate(-5deg);
  transition: transform 0.3s ease;
}

.personImage.round {
  border-radius: 50%;
  transform: scale(1.1) rotate(5deg);
}

.colorBlock {
  width: 120px;
  height: 120px;
  margin: 0 15px;
  border-radius: 25%;
  transform: scale(1.1) rotate(5deg);
  transition: transform 0.3s ease;
}

.yellow { background-color: #000; }
.green { background-color: #d82809f1; }
.blue { background-color: #0c7c2e; }

.circle {
  border-radius: 50%;
  transform: scale(1.1) rotate(-5deg);
}

.roundedSquare {
  border-radius: 20%;
  transform: scale(1.1) rotate(5deg);
}

.rectangle {
  width: 160px;
  height: 80px;
  border-radius: 10%;
  transform: scale(1.1) rotate(-5deg);
}

.textContent {
  text-align: center;
  margin: 20px 0;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.nathanLogo {
  max-width: 200px;
  margin: 10px 0;
}

p {
  font-size: 18px;
  color: #555;
}

/* Responsive scaling for smaller screens */
@media (max-width: 1024px) {
  .personImage,
  .colorBlock {
    width: 100px;
    height: 100px;
    margin: 0 12px;
  }

  .rectangle {
    width: 140px;
    height: 70px;
  }

  h2 {
    font-size: 22px;
  }

  .nathanLogo {
    max-width: 180px;
  }

  p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .personImage,
  .colorBlock {
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }

  .rectangle {
    width: 120px;
    height: 60px;
  }

  h2 {
    font-size: 20px;
  }

  .nathanLogo {
    max-width: 160px;
  }

  p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .personImage,
  .colorBlock {
    width: 60px;
    height: 60px;
    margin: 0 8px;
  }

  .rectangle {
    width: 100px;
    height: 50px;
  }

  h2 {
    font-size: 18px;
  }

  .nathanLogo {
    max-width: 140px;
  }

  p {
    font-size: 12px;
  }
}
