/* Insights.module.css */
.insightsSection {
    padding: 60px 20px;
  }
  
  .sectionHeading {
    position: relative;
    margin-bottom: 40px;
    padding-left: 20px;
  }
  
  .sectionHeading h2 {
    font-size: 1.4rem;
    color: #333;
  }
  
  .sectionHeading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #41e096;
  }
  
  .insightsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .insightCard {
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .insightImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .insightContent {
    padding: 20px;
  }
  
  .category {
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .readMore {
    color: #41e096;
    text-decoration: none;
    font-weight: bold;
    background-color: #fff;
    border-color: #fff;
  }
  
  .readMore:hover {
    text-decoration: underline;
    color: #32a372
  }
  
  @media (max-width: 768px) {
    .insightCard {
      flex: 0 0 100%;
    }

    .sectionHeading h2 {
      font-size: 1.1rem;
    }
  }

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 1rem;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #1a1a1a;
}

.modalGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.modalImageSection {
  width: 100%;
}

.modalImage {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  object-fit: cover;
}

.modalDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modalTag {
  display: inline-block;
  padding: 4px 8px;
  background-color: #41E096;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  align-self: flex-start;
}

.modalDetails h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.modalDescription {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.modalDescription p {
  margin-bottom: 1rem;
}

.modalDescription p:last-child {
  margin-bottom: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modalOverlay {
    padding: 1rem;
  }

  .modalContent {
    padding: 1.5rem;
  }

  .modalDetails h2 {
    font-size: 1.5rem;
  }

  .modalDescription {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .modalOverlay {
    padding: 0;
  }

  .modalContent {
    border-radius: 0;
    max-height: 100vh;
    padding: 1rem;
  }

  .closeButton {
    top: 0.5rem;
    right: 0.5rem;
  }

  .modalDetails h2 {
    font-size: 1.25rem;
  }

  .modalDescription {
    font-size: 0.9rem;
  }
}
