.servicesContainer {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  color: #fff;
}

.servicesOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 40px;
  border-radius: 15px;
  max-width: 1400px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.servicesHeading {
  font-size: 2rem;
  margin-bottom: 40px;
  text-align: center;
  color: #41e096;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.serviceGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.serviceCard {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.serviceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.serviceImageWrapper {
  height: 200px;
  overflow: hidden;
}

.serviceImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.serviceCard:hover .serviceImage {
  transform: scale(1.1);
}

.serviceContent {
  padding: 20px;
}

.serviceTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #41e096;
}

.serviceDescription {
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .servicesOverlay {
    padding: 30px 20px;
  }

  .servicesHeading {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .serviceGrid {
    grid-template-columns: 1fr;
  }

  .serviceCard {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .servicesHeading {
    font-size: 1.7rem;
  }

  .serviceTitle {
    font-size: 1.3rem;
  }

  .serviceDescription {
    font-size: 0.9rem;
  }
}