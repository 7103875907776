.projects-container {
  padding: 40px 0;
  background-color: #fff;
  overflow: hidden;
}

.projects-heading {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #333;
}

.projects-subheading {
  font-size: 28px;
  margin-top: 30px;
  color: #333;
}

.slider-container {
  margin: 0 auto;
  max-width: 100%; 
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff; /* Limit the width of the slider */
}

.project-item {
  padding: 0 20px; 
  padding-top: 20px;
  padding-bottom: 20px; /* Increased padding between logos */
}

.project-image {
  max-width: 300px;  /* Increased from 100px to 200px */
  max-height: 200px;  /* Increased from 50px to 100px */
  width: auto;
  height: auto;
  object-fit: contain;
  padding: 40px;
}

.project-image .hover


/* Override some slick-carousel styles */
.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}