.supportSection {
    padding: 40px 0;
  }
  
  .container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
  }
  
  .supportTextWrapper {
    border-radius: 5px;
    display: flex;
    align-items: center;
    top: -30px;
    color:#000;
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
    padding-top: 10px;
    font-size: 1.3rem;
  }
  
  .supportTextWrapper::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 25px;
    margin-right: 10px;
    border-radius: 2px;
    color: #000;
    background-color: #41e096;

  }
  
  .supportText {
    font-size: 0.7rem;
    font-weight: bold;
    color: #000; /* Dark blue */
    margin: 0;
    padding-bottom: 20px;
  }
  
  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .imageWrapper {
    flex: 1;
    padding-right: 20px;
  }
  
  .supportImage {
    width: 80%;
    border-radius: 10px;
    padding-left: 80px;
  }
  
  .textContent {
    flex: 1;
    padding-left: 20px;
  }
  
  .title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #000; /* Dark blue */
    margin-bottom: 15px;
  }
  
  .description {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
    padding-right: 30px;
  }
  
  .contactButton {
    background-color: #41E096; /* Bright blue */
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .contactButton:hover {
    background-color: #32a372; /* Darker blue on hover */
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .imageWrapper, .textContent {
      padding: 0;
    }
  
    .textContent {
      margin-top: 20px;
    }
  
    .supportImage {
      width: 80%; /* Adjust image size for smaller screens */
      margin: 0 auto;
      padding-left: 0px;
    }

    .title{
      font-size: 1.3rem;
    }

    .description {
      font-size: 0.9rem;
      text-align: left;
      padding-left: 50px;
    }
  }
  