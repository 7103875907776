.contactStrip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 8px 5%;
  font-family: 'Arial', sans-serif;
}

.contactInfo {
  display: flex;
  align-items: center;
}

.phoneIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #fff;
}

.phoneNumber {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.socialIcons {
  display: flex;
  gap: 15px;
}

.iconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333333;
  transition: all 0.3s ease;
}

.icon {
  width: 14px;
  height: 14px;
  color: #ffffff;
  transition: color 0.3s ease;
}

/* Twitter */
.iconLink:hover .twitterIcon {
  color: #1DA1F2;
}

/* Facebook */
.iconLink:hover .facebookIcon {
  color: #1877F2;
}

/* LinkedIn */
.iconLink:hover .linkedinIcon {
  color: #0077B5;
}

/* Ensure background color remains unchanged on hover */
.iconLink:hover {
  background-color: #333333;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .contactStrip {
    padding: 10px 2%;
  }

  .phoneIcon {
    width: 16px;
    height: 16px;
  }

  .phoneNumber {
    font-size: 14px;
  }

  .socialIcons {
    gap: 10px;
  }

  .iconLink {
    width: 24px;
    height: 24px;
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}