.app {
    font-family: 'Arial', sans-serif;
}

.main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.serviceSection {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.serviceSection h2 {
    color: #333;
    margin-bottom: 1rem;
}

.serviceSection p {
    color: #666;
    margin-bottom: 1.5rem;
}

.iconGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1.5rem;
}

.iconItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.ctaSection {
    background-color: #f8f9fa;
    text-align: center;
    padding: 3rem;
    border-radius: 10px;
    margin-top: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
}

.ctaSection h2 {
    margin-bottom: 1rem;
}

.ctaSection p {
    margin-bottom: 2rem;
}

.ctaButton {
    background-color: #41E096;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.ctaButton:hover {
    background-color: #f8f9fa;
}

@media (max-width: 768px) {
    .main {
        padding: 1rem;
    }
    
    .serviceSection {
        padding: 1.5rem;
    }
    
    .iconGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}