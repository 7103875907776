.app {
    font-family: 'Arial', sans-serif;
}

.main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.heroContent {
    flex: 1;
}

.heroContent h1 {
    font-size: 2.2rem;
    color: #32325c;
    margin-bottom: 1rem;
}

.heroContent p {
    font-size: 1.2rem;
    color: #555;
}

.heroImage {
    width: 40%;
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.features h3 {
    font-size: 1.2rem;
}

.featureCard {
    text-align: center;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.featureCard:hover {
    transform: translateY(-5px);
}

.featureIcon {
    font-size: 2.2rem;
    color: #41E096;
    margin-bottom: 1rem;
}


.description p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 3rem;
}

.benefits {
    background-color: #fffc;
    color: #555;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 3rem;
}

.benefits h2 {
    margin-bottom: 1rem;
}

.benefits ul {
    list-style-type: none;
    padding-left: 0;
}

.benefits li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
    font-size: 1.1rem;
}

.benefits li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #000;
}

.partners {
    margin-bottom: 3rem;
}

.partners h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.partnerLogos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.partnerLogo {
    text-align: center;
    margin-bottom: 1rem;
}

.partnerLogo img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 0.5rem;
}

.callToAction {
    background-color: #f8f9fa;
    padding: 3rem;
    text-align: center;
    border-radius: 10px;
}

.callToAction h2 {
    color: #32325c;
    margin-bottom: 1rem;
}

.callToAction p {
    margin-bottom: 2rem;
}

.ctaButton {
    background-color: #41E096;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ctaButton:hover {
    background-color: #32a372;
    color:#fff
}


/* Responsive styles */
    @media (max-width: 768px) {
        .hero {
            flex-direction: column;
        }

        .heroImage {
            width: 100%;
            margin-top: 2rem;
        }

        .features {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }

        .features h3 {
            font-size: 1rem;
        }

        .benefits {
            padding: 1rem;
        }

        .benefits h2 {
            font-size: 1.3rem;
        }

        .benefits ul {
            padding-left: 1rem;
        }

        .benefits li {
            font-size: 1rem;
        }

        .partnerLogo img {
            width: 100px;
            height: 100px;
        }

        .callToAction {
            padding: 2rem;
        }

        .callToAction h2 {
            font-size: 1.3rem;
        }

        .callToAction p {
            font-size: 1rem;
        }

        .ctaButton {
            padding: 0.5rem 1rem;
            font-size: 1rem;
        }

        .description p {
            font-size: 1rem;
        }

        .heroContent h1 {
            font-size: 1.6rem;
        }

        .heroContent p {
            font-size: 1.1rem;
        }
    }
