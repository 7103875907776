.blog-detail-container {
    max-width: 800px;
    margin: 40px auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 40px;
  }
  
  .blog-category {
    font-size: 14px;
    font-weight: bold;
    color: #00c853;
    margin-bottom: 16px;
    display: block;
    text-transform: uppercase;
  }
  
  .blog-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 24px;
    line-height: 1.2;
  }
  
  .blog-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 14px;
    color: #666;
  }
  
  .blog-description {
    font-size: 18px;
    color: #444;
    line-height: 1.8;
    margin-bottom: 32px;
  }
  
  .back-link {
    display: inline-block;
    color: #00c853;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }
  
  .back-link:hover {
    text-decoration: underline;
    color: #32a372;
  }